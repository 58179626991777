import assert from 'assert';
import { ImportOptions, ImportSource, NonInstantListeningImportSource } from 'components/library/import/upload/import';
import { InstantListeningSupportedData } from 'components/library/import/utils';
import { ALLOWED_MIME_TYPES } from 'interfaces';
import { Nullable } from 'utils/types';

import { createFileListenableContent } from './blob';
import { ListenableContent } from './types';
import { createURLListenableContent } from './url';
import { ImportCompleteCallback, isFileListenableContent } from './utils';

export type InstantListeningCreationResult = {
  listenableContent: Nullable<ListenableContent>;
  isTrulyInstantListening: boolean;
};

export const createTrueInstantListeningListenableContent = (
  data: InstantListeningSupportedData,
  analyticsProperties: Record<string, unknown>
): Nullable<ListenableContent> => {
  const file = data instanceof File ? data : new File([data.text], data.title, { type: ALLOWED_MIME_TYPES.TXT });
  return createFileListenableContent(file, analyticsProperties);
};

export const getListeningOverhaulImportTask = async (importSource: ImportSource, importOptions: ImportOptions, callback: ImportCompleteCallback) => {
  assert(importOptions.isListeningOverhaul, 'isListeningOverhaulVariant should be true on getListeningOverhaulImportTask');
  if (importOptions.listenableContent && isFileListenableContent(importOptions.listenableContent)) {
    importOptions.listenableContent.setImportOptions({
      callback,
      folderId: importOptions.folderId
    });
    return importOptions.listenableContent;
  }
  return createURLListenableContent(importSource as NonInstantListeningImportSource, importOptions, callback);
};
