import React from 'react';

import { getPSPDFKitAssetUrl, PSPDFKIT_PREFETCH_LINKS } from 'lib/pdf/preload';

export const PrefetchPSPDFKit = () => {
  return (
    <>
      {PSPDFKIT_PREFETCH_LINKS.map(fileName => (
        <link key={fileName} rel="prefetch" href={getPSPDFKitAssetUrl(fileName)} />
      ))}
    </>
  );
};
