import React from 'react';

export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.95228 9.90456C5.95281 9.90456 6.8904 9.60252 7.67069 9.08652L10.4268 11.849C10.603 12.0252 10.8422 12.1133 11.0876 12.1133C11.6162 12.1133 12 11.7042 12 11.1883C12 10.9491 11.9182 10.7163 11.742 10.5401L8.99843 7.79025C9.56476 6.99109 9.90456 6.00944 9.90456 4.95228C9.90456 2.22758 7.67069 0 4.95228 0C2.22758 0 0 2.22758 0 4.95228C0 7.67698 2.22758 9.90456 4.95228 9.90456ZM4.95228 8.58311C2.95752 8.58311 1.32145 6.94704 1.32145 4.95228C1.32145 2.96382 2.95752 1.32145 4.95228 1.32145C6.94074 1.32145 8.58311 2.96382 8.58311 4.95228C8.58311 6.94704 6.94074 8.58311 4.95228 8.58311Z"
      fill="#587393"
    />
  </svg>
);
