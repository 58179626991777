import React from 'react';

export const ArrowDown = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.86328 10.6667C8.06836 10.6608 8.25586 10.5846 8.4082 10.4206L12.8613 5.86198C12.9902 5.73308 13.0605 5.56902 13.0605 5.37566C13.0605 4.98894 12.7559 4.67839 12.3691 4.67839C12.1816 4.67839 12 4.75456 11.8652 4.88933L7.86914 8.99675L3.86133 4.88933C3.72656 4.76042 3.55078 4.67839 3.35742 4.67839C2.9707 4.67839 2.66602 4.98894 2.66602 5.37566C2.66602 5.56902 2.73633 5.73308 2.86523 5.86198L7.32422 10.4206C7.48242 10.5846 7.6582 10.6667 7.86328 10.6667Z"
        fill="#587393"
      />
    </svg>
  );
};
