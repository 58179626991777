import { UploadErrorCallback } from '@uppy/core';
import { Result, TransloaditAssemblyCreatedCallback, TransloaditResultCallback } from '@uppy/transloadit';
import UrlPlugin from '@uppy/url';
import { getUppy } from './uppy';
import { captureError, matchMimeType } from '../utils';
import { AUTO_IMPORT_REMOTE_MIME_TYPES } from '../constants';

function addFile(file: File, source?: string): string {
  const uppy = getUppy();

  return uppy.addFile({
    name: file.name,
    type: file.type,
    data: file,
    isRemote: false,
    source
  });
}

async function addUrl(url: string): Promise<string> {
  const uppy = getUppy();
  const urlUploader = uppy.getPlugin<UrlPlugin>('url');

  if (!urlUploader) {
    throw new Error('Unable to import file by url');
  }

  const fileId = await (urlUploader.addFile(url) as unknown as Promise<undefined | string | Error>);

  if (!fileId || fileId instanceof Error) {
    throw new Error('Unable to import file by url');
  }

  return fileId;
}

async function addFileOrUrl(data: File | string, source?: string): Promise<string> {
  if (typeof data === 'string') return addUrl(data);
  return addFile(data, source);
}

export type ConversionResult = Result;

export function convert(data: File | string, source?: string): Promise<ConversionResult> {
  // ESLint: Promise executor functions should not be async
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<ConversionResult>(async (resolve, reject) => {
    const uppy = getUppy();
    const fileId = await addFileOrUrl(data, source);
    const errorHandler = captureError();

    const handleError: UploadErrorCallback<Record<string, unknown>> = file => {
      if (!file || fileId !== file.id) return;
      errorHandler.onError(new Error('Unable to convert file'));
    };

    uppy.on('upload-error', handleError);
    errorHandler.promise.catch(e => {
      uppy.off('upload-error', handleError);
      reject(e);
    });

    uppy.upload();

    const assemblyId = await new Promise<string>(resolve => {
      const handleAssemblyCreated: TransloaditAssemblyCreatedCallback = (assembly, ids) => {
        if (!ids.includes(fileId)) return;

        uppy.off('transloadit:assembly-created', handleAssemblyCreated);
        resolve(assembly.assembly_id);
      };
      uppy.on('transloadit:assembly-created', handleAssemblyCreated);
      errorHandler.promise.catch(() => uppy.off('transloadit:assembly-created', handleAssemblyCreated));
    });

    const conversionResult = await new Promise<Result>(resolve => {
      const handleConvertionResult: TransloaditResultCallback = (step, result, assembly) => {
        if (assembly.assembly_id !== assemblyId) return;
        if (!result.localId || result.localId !== fileId) return;

        if (step === 'convert' || (AUTO_IMPORT_REMOTE_MIME_TYPES.some(mimeType => matchMimeType(mimeType, result.mime)) && step === ':original')) {
          uppy.off('transloadit:result', handleConvertionResult);
          resolve(result);
        }
      };

      uppy.on('transloadit:result', handleConvertionResult);
      errorHandler.promise.catch(() => uppy.off('transloadit:result', handleConvertionResult));
    });

    errorHandler.clear();
    uppy.off('upload-error', handleError);

    resolve(conversionResult);
  });
}
