import React from 'react';

export const ArrowUp = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.86328 5.33333C8.06836 5.33919 8.25586 5.41536 8.4082 5.57942L12.8613 10.138C12.9902 10.2669 13.0605 10.431 13.0605 10.6243C13.0605 11.0111 12.7559 11.3216 12.3691 11.3216C12.1816 11.3216 12 11.2454 11.8652 11.1107L7.86914 7.00325L3.86133 11.1107C3.72656 11.2396 3.55078 11.3216 3.35742 11.3216C2.9707 11.3216 2.66602 11.0111 2.66602 10.6243C2.66602 10.431 2.73633 10.2669 2.86523 10.138L7.32422 5.57942C7.48242 5.41536 7.6582 5.33333 7.86328 5.33333Z"
        fill="#587393"
      />
    </svg>
  );
};
