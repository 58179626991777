import { ALLOWED_MIME_TYPES } from 'interfaces/import';

import { EPUBFileListenableContent, FileListenableContent, HTMLFileListenableContent, TXTFileListenableContent } from './';
import { PDFFileListenableContent } from './impl/PDFFileListenableContent';

export const createFileListenableContent = (file: File, analyticsProperties: Record<string, unknown>): FileListenableContent | null => {
  switch (file.type) {
    case ALLOWED_MIME_TYPES.PDF:
      return new PDFFileListenableContent(file, analyticsProperties);
    case ALLOWED_MIME_TYPES.EPUB:
      return new EPUBFileListenableContent(file, analyticsProperties);
    case ALLOWED_MIME_TYPES.HTML:
      return new HTMLFileListenableContent(file, analyticsProperties);
    case ALLOWED_MIME_TYPES.TXT:
      return new TXTFileListenableContent(file, analyticsProperties);
    default:
      return null;
  }
};
