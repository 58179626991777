import React from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import Head from 'next/head';

import { PrefetchPSPDFKit } from './HeadForPrefetchingPSPDFKit';

type MetaComponentProps = {
  disablePSPDFKitPrefetch?: boolean;
  pspdfkitBaseUrl?: string;
};

const MetaComponent = (
  props: MetaComponentProps = {
    disablePSPDFKitPrefetch: false,
    pspdfkitBaseUrl: '/'
  }
) => {
  const { t } = useTranslation('common');
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={t('meta_description')} />

      <meta name="facebook-domain-verification" content="mtashqznzaefobwbeq8dyc0so0cka3" />
      <meta property="og:url" content="https://speechify.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t('meta_title')} />
      <meta property="og:description" content={t('meta_description')} />
      <meta property="og:image" content="https://speechify.com/SpeechifyOgLogo.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="speechify.com" />
      <meta property="twitter:url" content="https://speechify.com/" />
      <meta name="twitter:title" content={t('meta_title')} />
      <meta name="twitter:description" content={t('meta_description')} />
      <meta name="twitter:image" content="https://speechify.com/SpeechifyOgLogo.png" />
      {!props.disablePSPDFKitPrefetch && <PrefetchPSPDFKit />}
    </Head>
  );
};

export default MetaComponent;
