import React, { useEffect, useMemo } from 'react';

import ArrowOutwardVoiceover from 'assets/icons/arrow-outward-voiceover';
import MP3Doc from 'assets/icons/mp3Doc';
import VoiceoverPremiumIcon from 'assets/icons/voiceoverPremium';
import ModalComponent from 'components/elements/Modal';
import { useTranslation } from 'hooks/useTypedTranslation';
import { shuffle } from 'lodash';
import { logSegmentEvent } from 'utils/analytics';

type DownloadChoiceOnItemProps = {
  onClose: () => void;
  onOpenDownloadMp3: () => void;
  open?: boolean;
};

const DownloadChoiceOnItem: React.FC<DownloadChoiceOnItemProps> = ({ onClose, onOpenDownloadMp3, open = false }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_download_choice_modal_shown');
    }
  }, [open]);

  const handleClose = () => {
    logSegmentEvent('web_app_download_choice_modal_closed');
    onClose();
  };

  const handleDownload = (key: string) => {
    if (key === 'mp3') {
      logSegmentEvent('web_app_download_choice_mp3_clicked');
      onOpenDownloadMp3();
    } else {
      logSegmentEvent('web_app_download_choice_voiceover_clicked');
      window.open('https://studio.speechify.com/?fromWebApp=true', '_blank');
    }
  };

  const bodyPart = useMemo(
    () =>
      shuffle([
        {
          key: 'mp3',
          image: <MP3Doc height={59} />,
          title: t('Download MP3'),
          content: t('Download up to 60 min of your file Enjoy your audio offline, with a selected voice.* (upto 3hr/month)'),
          additional: t("*Celebrity voices aren't available due to author's rights")
        },
        {
          key: 'voiceover',
          image: (
            <>
              <ArrowOutwardVoiceover className="absolute right-2 top-2" />
              <VoiceoverPremiumIcon />
            </>
          ),
          title: t('Generate Voice Over'),
          content: t(
            'Use our voice over software to turn your text into audiobooks, corporate trainings, or even YouTube videos. Create the perfect audio experience by fine tuning the tone, speed, pronunciation, and more.'
          )
        }
      ]),
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ModalComponent open={open} showCloseButton onClose={handleClose} classNames="!max-w-[720px] !w-[720px] dark:bg-glass-700" dialogClassNames="z-2000">
      <div className="flex w-full flex-none flex-col justify-center px-8 pb-8">
        <span className="mt-4 text-center text-2xl font-bold text-glass-700 dark:text-glass-0">{t('How do you want to download your file?')}</span>
        <div className="mt-5 flex w-full justify-between gap-4">
          {bodyPart.map(data => (
            <div
              onClick={() => handleDownload(data.key)}
              key={data.key}
              className="relative flex w-[320px] cursor-pointer flex-col items-center gap-2 rounded border border-glass-300 px-5 py-4 hover:border-electric-350 hover:bg-electric-200 dark:border-glass-600 dark:hover:border-[#91CDFF] dark:hover:bg-[#283750]"
            >
              {data.image}
              <span className="text-center text-base font-bold text-glass-700 dark:text-glass-0">{data.title}</span>
              <span className="text-center text-sm text-glass-600 dark:text-glass-350">{data.content}</span>
              {data.additional && <span className="text-center text-xs text-glass-400 dark:text-glass-350">{data.additional}</span>}
            </div>
          ))}
        </div>
      </div>
    </ModalComponent>
  );
};

export default DownloadChoiceOnItem;
