import type { Callback } from 'lib/speechify/adaptors/lib/typeAliases';

import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';

import { BaseListenableContent, ContentMetaType, ContentType } from '../base';
import { SDKLibraryFacade } from '../../library';

export abstract class BaseURLListenableContent extends BaseListenableContent {
  public readonly folderId?: string;
  public readonly itemId: Promise<string>;
  public readonly contentType: ContentType = ContentType.url;

  public readonly url: string;
  protected _itemIdResolver!: (itemId: string) => void;

  protected _name: string;
  protected _mimeType: string;

  public readonly onImportCompleteCallback: Callback<SpeechifyURI>;

  constructor({
    folderId,
    mimeType,
    name,
    onImportCompleteCallback,
    url
  }: {
    folderId?: string;
    mimeType: string;
    name: string;
    onImportCompleteCallback: Callback<SpeechifyURI>;
    url: string;
  }) {
    super();
    this._mimeType = mimeType;
    this._name = name || url;
    this.url = url;
    this.folderId = folderId;
    this.itemId = new Promise(resolve => {
      this._itemIdResolver = resolve;
    });
    this.onImportCompleteCallback = onImportCompleteCallback;
  }

  abstract get metaType(): ContentMetaType;

  content = async (): Promise<string> => {
    return this.url;
  };

  public get title(): string {
    return this._titleOverride || this._name;
  }

  public get wordCount(): number {
    // TODO: Implement word count for URL item
    return 0;
  }

  override onItemIdReady(itemId: string): void {
    super.onItemIdReady(itemId);
    // this is used by useListenableContentItemId state to trigger rerender when item ID is ready,
    // which is useful for hiding/displaying features that depends on item ID readiness.
    this._itemIdResolver(itemId);

    SDKLibraryFacade.singleton.getItem(itemId).then(item => {
      this.updateTitle(item.title);
    });
  }
}
